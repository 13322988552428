const userOptions = [
  {
    key: "ben",
    text: "Ben",
    value: 1,
  },
  {
    key: "gin",
    text: "Gin",
    value: 2,
  },
  {
    key: "aileen",
    text: "Aileen",
    value: 3,
  },
  {
    key: "xinying",
    text: "Xin Ying",
    value: 4,
  },
  {
    key: "amelia",
    text: "Amelia",
    value: 5,
  },
  {
    key: "edmund",
    text: "Edmund",
    value: 6,
  },
  {
    key: "corinne",
    text: "Corinne",
    value: 7,
  },
  {
    key: "amanda",
    text: "Amanda",
    value: 8,
  },
  {
    key: "hazel",
    text: "Hazel",
    value: 9,
  },
  {
    key: "tiffany",
    text: "Tiffany",
    value: 10,
  },
  {
    key: "silvia",
    text: "Silvia",
    value: 11,
  },
  {
    key: "hidayah",
    text: "Hidayah",
    value: 12,
  },
  {
    key: "zarinah",
    text: "Zarinah",
    value: 13,
  },
  {
    key: "doline",
    text: "Doline",
    value: 14,
  },
  {
    key: "seria",
    text: "Seri A",
    value: 15,
  },
  {
    key: "rosabella",
    text: "Rosabella",
    value: 16,
  },
  {
    key: "jingyi",
    text: "Jing Yi",
    value: 17,
  },
  {
    key: "bibi",
    text: "Bibi",
    value: 18,
  },
  {
    key: "ashikin",
    text: "Ashikin",
    value: 19,
  },
  {
    key: "rasidah",
    text: "Rasidah",
    value: 20,
  },
  {
    key: "seriw",
    text: "Seri W",
    value: 21,
  },
  {
    key: "xinee",
    text: "Xin Ee",
    value: 22,
  },
  {
    key: "kaixin",
    text: "Kai Xin",
    value: 23,
  },
  {
    key: "carol",
    text: "Carol",
    value: 24,
  },
  {
    key: "desheen",
    text: "De Sheen",
    value: 25,
  },
  {
    key: "ryan",
    text: "Ryan",
    value: 26,
  },
  {
    key: "zoanne",
    text: "Zoanne",
    value: 27,
  },
  {
    key: "sharon",
    text: "Sharon",
    value: 28,
  },
  {
    key: "diyana",
    text: "Diyana",
    value: 29,
  },
  {
    key: "nurul",
    text: "Nurul",
    value: 30,
  },
  {
    key: "siewwei",
    text: "Siew Wei",
    value: 31,
  },
  {
    key: "yusri",
    text: "Yusri",
    value: 32,
  },
  {
    key: "timothy",
    text: "Timothy",
    value: 33,
  },
  {
    key: "randall",
    text: "Randall",
    value: 34,
  },
  {
    key: "maryann",
    text: "Maryann",
    value: 35,
  },
  {
    key: "renuka",
    text: "Renuka",
    value: 36,
  },
  {
    key: "joan",
    text: "Joan",
    value: 37,
  },
  {
    key: "germaine",
    text: "Germaine",
    value: 38,
  },
  {
    key: "jimmy",
    text: "Jimmy",
    value: 39,
  },
  {
    key: "anthea",
    text: "Anthea",
    value: 40,
  },
  {
    key: "ruthura",
    text: "Ruthura",
    value: 41,
  },
  {
    key: "mona",
    text: "Mona",
    value: 42,
  },
  {
    key: "betty",
    text: "Betty",
    value: 43,
  },
  {
    key: "farzana",
    text: "Farzana",
    value: 44,
  },
  {
    key: "nadiah",
    text: "Nadiah",
    value: 45,
  },
  {
    key: "regina",
    text: "Regina",
    value: 46,
  },
  {
    key: "saedah",
    text: "Saedah",
    value: 47,
  },
  {
    key: "sitimariam",
    text: "Siti Mariam",
    value: 48,
  },
  {
    key: "nurhidayah",
    text: "Nur Hidayah",
    value: 49,
  },
  {
    key: "michelle",
    text: "Michelle",
    value: 50,
  },
  {
    key: "khalysha",
    text: "Khalysha",
    value: 51,
  },
  {
    key: "emily",
    text: "Emily",
    value: 52,
  },
  {
    key: "shuhadah",
    text: "Noor Shuhadah",
    value: 53,
  },
  {
    key: "junxiang",
    text: "Jun Xiang",
    value: 54,
  },
  {
    key: "junde",
    text: "Jun De",
    value: 55,
  },
  {
    key: "nurulaini",
    text: "Nurul Aini",
    value: 56,
  },
  {
    key: "bruce",
    text: "Bruce Wong",
    value: 57,
  },
  {
    key: "yuni",
    text: "Seri Wahyuni",
    value: 58,
  },
  {
    key: "timothy",
    text: "Timothy Lee",
    value: 59,
  },
  {
    key: "luthfi",
    text: "Mohammad Luthfi",
    value: 60,
  },
  {
    key: "luthfi",
    text: "Farisyah Ameera Afani",
    value: 61,
  },

];

export default userOptions;
